<template>
  <div class="outer-page">
    <div class="sub-banner">
      <div class="bgImg">
        <div class="sub-txt">
          <h6>{{ getMenu.tips }}</h6>
          <p>{{ getMenu.name }}</p>
        </div>
      </div>
    </div>
    <div class="sub-section warpper">
      <div class="msgleft">
        <h3>您好 HELLO！</h3>
        <div class="desc">
          感谢您来到农神控股（集团），若您有意见或者建议，请为我们留言或者使用以下方式联系我们，我们将尽快给您回复，并为您提供最真诚的服务，谢谢。
        </div>
        <ul>
          <!-- <li>电话：023-46736767</li> -->
          <li>全国销售热线：400-133-8979</li>
          <li>售后客服专线：023-68866321 </li>
          <li>邮箱：nongshenkonggu@126.com</li>
          <li>
            地址：重庆市荣昌区昌州街道昌龙大道53号( 重庆市畜牧科学院5号楼9楼)
          </li>
        </ul>
        <div class="wx">
          <img src="../../../public/imgs/logo2.jpg" width="144" height="144" />
        </div>
      </div>
      <div class="msgright">
        <div class="msgform">
          <Form>
            <div class="row">名字/Name</div>
            <div class="row txt name">
              <input class="txt_input" v-model="contactForm.messagePeople" />
            </div>
            <div class="row">电话/Phone</div>
            <div class="row txt">
              <input class="txt_input" v-model="contactForm.tel" />
            </div>
            <div class="row">信息/Information</div>
            <div class="row txt msg">
              <textarea
                :rows="5"
                class="txt_msg"
                v-model="contactForm.messageContent"
              />
            </div>
          </Form>
          <div class="btn">
            <a class="btnsubmit" @click="submit">SEND</a>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="commap">
        <!-- <img src="../../../public\imgs\map01.png" alt="" /> -->
        <map-comp></map-comp>
      </div>
    </div>
  </div>
</template>

<script>
import mapComp from "./map";
export default {
  name: "",
  components: {
    mapComp,
  },
  data() {
    return {
      contactForm: {
        messagePeople: "",
        tel: "",
        messageContent: "",
      },
    };
  },
  computed: {
    getMenu() {
      let menu = this.$store.state.permission.menu.filter(
        (item) => item.router == this.$store.state.permission.currentRoute
      )[0];
      return menu;
    },
  },
  methods: {
    submit() {
      if (this.contactForm.messagePeople == "") {
        this.$Message.error("姓名不能为空！");
      } else if (this.contactForm.tel == "") {
        this.$Message.error("电话不能为空！");
      } else if (this.contactForm.messageContent == "") {
        this.$Message.error("内容不能为空！");
      } else {
        this.$post(this.$api.SEND_MESSAGE, this.contactForm)
          .then(() => {
            this.$Message.success("留言发送成功！");
          })
          .finally(() => {
            this.contactForm = {
              messagePeople: "",
              tel: "",
              messageContent: "",
            };
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
